// .page {
//     width: $width;
// }

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
}

.h-vh {
    height: 100vh;
}
